import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useCookies } from "react-cookie";

export const useCookieOptions = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const { pushInstruction } = useMatomo();

  const cookieCategories = [
    "essential",
    "statistics",
    // "socialMedia",
    // "thirdParty",
  ];

  const setConsents = (consents: string[]) => {
    setCookie("cookieConsent", consents, {
      path: "/",
      secure: true,
    });

    if (consents.includes("statistics")) {
      pushInstruction("setConsentGiven");
      pushInstruction("setCookieConsentGiven");
    } else {
      pushInstruction("forgetConsentGiven");
      pushInstruction("forgetCookieConsentGiven");
    }
  };

  const setAllConsents = () => {
    setConsents(cookieCategories);
  };

  const setEssentialConsent = () => {
    setConsents(["essential"]);
  };

  const resetCookies = () => {
    // Remove cookie consent by setting an expired cookie
    setCookie("cookieConsent", "", { path: "/", expires: new Date(0) });

    // Forget Matomo consent
    pushInstruction("forgetConsentGiven");
    pushInstruction("forgetCookieConsentGiven");
  };

  return {
    cookieCategories,
    cookieConsent: cookies.cookieConsent,
    setAllConsents,
    setConsents,
    setEssentialConsent,
    resetCookies,
  };
};
